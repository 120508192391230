<template>
  <a-card :border="false">
    <div class="realtor">
      <!-- 查询表单 -->
      <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 8 }" :wrapper-col="{ xs: 16 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="大区事务所">
              <a-cascader
                :options="dealerList"
                v-model="regionalOffice"
                change-on-select
                placeholder="请选择大区/事务所"
                @change="onChange"
                :field-names="{ label: 'title', value: 'id', children: 'children' }"
              />
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="8" :xxl="8" :sm="12">
            <a-form-model-item label="经销商">
              <a-input v-model="searchData.dealerName" allowClear placeholder="经销商名称"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :xxl="4" :xl="5" :md="8" sm="2">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
              <a-button type="default" @click="reset()" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div>
        <a-button type="primary" @click="toHandler('edit')" v-if="isEdit">调整配额</a-button>
        <!-- <a-button type="primary" @click="toHandler('edit')">修改</a-button> -->
        <!-- <a-button type="primary" @click="toHandler('del')">删除</a-button> -->
      </div>
      <!-- 数据表格 -->
      <a-table
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
        :customRow="changeTableRow"
      >
        <span slot="surplusPreOrderCount" slot-scope="text, row">
          {{row.surplusPreOrderCount+row.temporaryRemainingQuota}}<br/>
          <span class="enzosp"><a @click="checkQuotaLog(row,1)">(固定：{{row.surplusPreOrderCount}} )</a></span><br/>
          <span class="enzosp"><a @click="checkQuotaLog(row,2)">(临时：{{row.temporaryRemainingQuota}} )</a></span>
        </span>
        <span slot="firmName" slot-scope="text, row">
          【{{ row.areaName }}】 {{ row.firmName }}
        </span>

        <span slot="baobeiCount" slot-scope="text, row">
          {{(row.orderCount + row.temporaryQuota) - row.baobeiCount}}<br/>
        </span>

      </a-table>
      <!-- 编辑表单 -->
      <DealerPreOrdersNum-edit-modal
        ref="DealerPreOrdersNumEditModal"
        @reload="getData"
      ></DealerPreOrdersNum-edit-modal>
    </div>

    <a-modal title="查看配额使用日志" :maskClosable="false" v-model="visible" width="50%" :footer="null">
      <a-table
        :rowKey="(record) => record.id"
        :columns="columnsCheck"
        :data-source="tableDataCheck"
        bordered
        :pagination="false"
      >
        <span slot="quotaType" slot-scope="text">
          <span v-if="text == 1">固定</span>
          <span v-if="text == 2">临时</span>
        </span>
        <span slot="quotaSpendingStatus" slot-scope="text">
          <span v-if="text == 1">支出</span>
          <span v-if="text == 2">收入</span>
          <span v-if="text == 3">系统增额</span>
          <span v-if="text == 4">系统减额</span>
        </span>
      </a-table>
    </a-modal>

  </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerPreOrdersNumEditModal from './components/DealerPreOrdersNumEditModal.vue'
import {
  delDealerPreOrdersNum,
  listDealerPreOrdersNum,
  selectByIdDealerPreOrdersNum,
} from './api/DealerPreOrdersNumApi'
import {checkPermission} from "@/utils/permissions";

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'dealerPreOrdersNum',
  components: {
    DealerPreOrdersNumEditModal,
  },
  data() {
    return {
      columns: columns,
      tableData: [],
      dealerList: [],
      regionalOffice: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: Object.assign({}, pageSource),
      selectedRowKeys: [],
      selectedRows: [],
      isEdit:checkPermission('quota_management:edit'),
      visible: false,
      tableDataCheck:[],
      columnsCheck: [
        // {
        //   title: '配额类型',
        //   dataIndex: 'quotaType',
        //   key: 'quotaType',
        //   /*width:150,*/
        //   align: 'center',
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'quotaType' },
        // },
        {
          title: '配额收支类型',
          dataIndex: 'quotaSpendingStatus',
          key: 'quotaSpendingStatus',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'quotaSpendingStatus' },
        },
        {
          title: '日志',
          dataIndex: 'reason',
          key: 'reason',
          width:350,
          align: 'center',
          // ellipsis: true,
        },
        // {
        //   title: '配额数',
        //   dataIndex: 'num',
        //   key: 'num',
        //   /*width:150,*/
        //   align: 'center',
        //   ellipsis: true,
        // },
        {
          title: '创建人',
          dataIndex: 'createUser',
          key: 'createUser',
          /*width:150,*/
          align: 'center',
          ellipsis: true,
        },
        {
          title: '使用时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width:180,
          align: 'center',
          ellipsis: true,
        },
      ],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    // 查看配额使用日志
    checkQuotaLog(row,type) {
      console.log(row)
      this.visible = true
      let params = {
        dealerId: row.dealerId,
        quotaType: type
      }
      this.axios.get('/api/base/quota/dealerQuotaLog/allQuotaLog', { params: params }).then((res) => {
        console.log("res",res.body)
        if(res.body){
          this.tableDataCheck=res.body
        }

        console.log("res",this.tableDataCheck)
      })
    },
    /**
     * 获取表格数据
     */
    getData() {
      this.tableLoading = true
      listDealerPreOrdersNum({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    /**
     * 筛选查询
     */
    getDataFilter() {
      this.page.current = 1;
      // this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      // 传输到后台
      this.searchData = {}
      // 页面展示
      this.regionalOffice = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },
    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    toHandler(name) {
      const _this = this
      if (name === 'add') {
        _this.$refs.DealerPreOrdersNumEditModal.setRowData({}, 'add')
        return
      }
      if (_this.selectedRows.length === 0) {
        _this.$notification.warning({
          message: '请选择一条记录',
        })
        return
      }
      switch (name) {
        case 'edit':
          _this.$refs.DealerPreOrdersNumEditModal.setRowData(_this.selectedRows[0], 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerPreOrdersNum(_this.selectedRows[0].id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getData()
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
      }
    },

    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
  },
  created() {
    this.getData()
    this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      // 获取大区事务所经销商数据
      this.dealerList = res.body
    })
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.enzosp{
  color: #b9b9b9;
  font-size: 12px;
}
</style>
